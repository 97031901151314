<template>
    <section class="wrapper activity-page">
        <CommonBlock title="Контроль">
            <div class="img">
                <img src="../assets/images/cabinet-img.svg" alt="cabinet">
            </div>
        </CommonBlock>
        <article class="content custom-scroll" :class="{hide: showConfirm}">
            <div class="content_bg">
                <header>
                    <ul>
                        <li v-for="tab in tabs" :key="tab.id">
                            <TabHeaderItem
                                :id="tab.id"
                                :label="tab.name"
                                :current-id="currentTab"
                                @change="changeTab"
                            />
                        </li>
                    </ul>
                </header>
                <div class="activity-create">
                    <CustomButton label="Додати запис" @click="$router.push({name: 'activity_create', params: {type: currentTab}})"/>
                    <div class="activities" v-if="items.length">
                        <article class="activity" v-for="item in viewItems" :key="item.id" :class="{empty: !(!!item.description)}">
                            <header class="activity-header" @click="collapse(item.id)">
                                <div>
                                    <div class="activity-name">{{ getActivityName(item, item.type) }}</div>
                                    <div class="activity-date">{{ item.activity_date }}</div>
                                </div>
                                <div class="activity-control">
                                    <button class="activity-edit" @click.stop="$router.push({name: 'activity_update', params: {id: item.id, type: currentTab}})">
                                        <svg width="20" height="20" viewBox="0 0 20 20">
                                            <g fill-rule="nonzero">
                                                <path d="M18.125 5.18l-1.322 1.323-3.306-3.31 1.322-1.323c.27-.27.739-.27 1.008 0l2.298 2.302c.278.278.278.73 0 1.008zM7.075 16.242l-3.305-3.31 8.547-8.56 3.306 3.31-8.547 8.56zm-4.069-1.714l2.474 2.478-3.445.971.971-3.45zm16.3-11.536L17.006.69c-.449-.449-2.07-1.298-3.363 0L1.998 12.344c-.102.102-.176.225-.212.363L.03 18.957c-.082.29.004.6.212.816.212.216.637.253.816.212l6.245-1.759c.14-.037.262-.11.364-.212L19.305 6.36c.927-.927.927-2.437 0-3.368z" transform="translate(-1739 -278) translate(680 252) translate(1059 26)"/>
                                            </g>
                                        </svg>
                                    </button>
                                    <button class="activity-delete" @click.stop="deleteArticle(item.id)">
                                        <svg width="17" height="20" viewBox="0 0 17 20">
                                            <g fill-rule="nonzero">
                                                <path d="M3.643 20h8.905c.893 0 1.619-.747 1.619-1.667v-10h1.214c.447 0 .81-.373.81-.833V5c0-.92-.727-1.667-1.62-1.667h-3.238V1.667C11.333.747 10.607 0 9.714 0H6.476c-.893 0-1.619.747-1.619 1.667v1.666H1.62C.726 3.333 0 4.081 0 5v2.5c0 .46.363.833.81.833h1.214v10c0 .92.726 1.667 1.619 1.667zm8.905-1.667H3.643v-10h8.905v10zM6.476 1.667h3.238v1.666H6.476V1.667zM1.618 5h12.953v1.667H1.62V5z" transform="translate(-1789 -278) translate(680 252) translate(1059 26) translate(50)"/>
                                                <path d="M10 16.19c.263 0 .476-.373.476-.833v-5c0-.46-.213-.833-.476-.833s-.476.373-.476.833v5c0 .46.213.833.476.833zM6.19 16.19c.263 0 .477-.373.477-.833v-5c0-.46-.214-.833-.477-.833-.262 0-.476.373-.476.833v5c0 .46.214.833.476.833z" transform="translate(-1789 -278) translate(680 252) translate(1059 26) translate(50)"/>
                                            </g>
                                        </svg>
                                    </button>
                                    <span class="collapse-idint" :class="{opened: item.id === openedItem}" v-if="item.description">
                                    <svg width="14" height="8" viewBox="0 0 14 8">
                                        <defs>
                                            <path id="uoqej2zgia" d="M2.408 6.68l5.278-5.035c.39-.371.447-.918 0-1.345-.448-.427-1.021-.372-1.41 0L.291 6.007c-.39.372-.39.974 0 1.346l5.983 5.707c.39.372 1.021.372 1.41 0 .39-.371.39-.973 0-1.345L2.409 6.68z"/>
                                        </defs>
                                        <g transform="translate(-1846 -284) translate(680 252) translate(1166 32) translate(2.857 -2.857) rotate(90 4 6.857)">
                                            <mask id="vxeis7gy9b" fill="#fff">
                                                <use xlink:href="#uoqej2zgia"/>
                                            </mask>
                                            <use fill="#FFF" fill-rule="nonzero" transform="matrix(-1 0 0 1 8 0)" xlink:href="#uoqej2zgia"/>
                                            <g fill="#1A2744" mask="url(#vxeis7gy9b)">
                                                <g>
                                                    <path d="M0 0H8V13.714H0z"/>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </span>
                                </div>
                            </header>
                            <transition name="slide">
                                <div class="activity-description" v-if="item.id === openedItem && !!item.description">
                                    <div v-html="urlify(item.description)"></div>
                                </div>
                            </transition>
                        </article>
                        <observer :key="observerKey" @intersect="infiniteHandler"/>
                    </div>
                </div>
            </div>
            <transition name="confirm-animation">
                <ConfirmComponent :title="confirmMessage" v-if="showConfirm" @confirm="deleteConfirm"/>
            </transition>
        </article>
    </section>
</template>

<script>

import CommonBlock from "@/components/CommonBlock";
import TabHeaderItem from "@/components/TabHeaderItem";
import CustomButton from "@/components/CustomButton";
import ActivityService from "../services/ActivityService";
import ConfirmComponent from "../components/ConfirmComponent";
import Observer from "@/components/app/Observer";

export default {
    name: "Activity",
    components: {
        Observer,
        CommonBlock,
        ConfirmComponent,
        TabHeaderItem,
        CustomButton
    },
    data() {
        return {
            currentTab: 1,
            openedItem: 1,
            deleteConfirmId: null,
            offset: 0,
            observerKey: 0,
            limit: 20,
            showConfirm: false,
            tabs: [{
                id: 1,
                name: 'Медійна діяльність',
            }, {
                id: 4,
                name: 'Ініціативи в раді'
            }, {
                id: 5,
                name: 'Акції'
            }],
            items: []
        };
    },
    created() {
        this.currentTab = +this.$route.query.type || this.currentTab;

        this.setActivities();
    },
    methods: {
        urlify(text) {
            if(!text) {
                return;
            }
            let urlRegex = /(https?:\/\/[^\s]+)/g;
            text = text.replace(/[\u00A0-\u9999<>\&]/g, i => '&#'+i.charCodeAt(0)+';');

            return text.replace(urlRegex, function(url) {
                return '<a href="' + url + '" target="_blank">' + url + '</a>';
            })
        },
        async deleteConfirm(confirm) {
            this.showConfirm = false;

            if(!confirm) {
                return;
            }

            let resp = await ActivityService.deleteUserActivity(this.deleteConfirmId).catch(this.handleApiError);

            if(resp) {
                this.$notify({
                    text: `Запис '${this.getActivityName(this.getArticle(this.deleteConfirmId), this.currentTab)}' видалено`,
                    type: 'success'
                });

                this.items.find((item, key) => {
                    if(item.id === this.deleteConfirmId) {
                        this.items.splice(key, 1);
                        return true;
                    }
                });
            }
        },
        async setActivities() {
            let resp = await ActivityService.getUserActivities(this.currentTab).catch(this.handleApiError);
            if(resp) {
                this.items = resp;
            }
        },
        getArticle(id) {
            return this.items.find(item => item.id === id);
        },
        async deleteArticle(id) {
            this.deleteConfirmId = id;
            this.showConfirm = true;
        },
        getActivityName(item, type) {
            if(type === 1) {
                return item.channel;
            }
            return item.title;
        },
        handleApiError(err) {
            if(err.message) {
                this.$notify({
                    text: err.message,
                    duration: 30_000,
                    ignoreDuplicates: true,
                    type: 'warn',
                });
            }

            this.items = [];
        },
        async infiniteHandler()
        {
            this.offset += this.limit;
        },
        collapse(id) {
            this.openedItem = this.openedItem === id ? null : id;
        },
        changeTab(id) {
            this.currentTab = +id;
            history.pushState({}, null, `?type=${this.currentTab}`);
            this.setActivities();
        },
    },
    computed: {
        confirmMessage() {
            return `Ви впевнені, що хочете видалити '${this.getActivityName(this.getArticle(this.deleteConfirmId), this.currentTab)}'?`;
        },
        viewItems() {
            return this.items.slice(0, this.offset);
        }
    }
}
</script>

<style lang="less">
.activity-page{
    .common-block{
        .title{
            margin-top: 60px;
        }
    }
}
.confirm-animation-leave-active, .confirm-animation-enter-active {
    transition: all .2s linear;
    .confirm-window{
        transition: all .2s ease-in;
    }
}
.confirm-animation-enter, .confirm-animation-leave-to {
    opacity: 0;
    .confirm-window{
        transform: translateY(-80%);
    }
}
.activity-create{
    text-align: center;
    padding: 20px 0;
    .custom-button{
        width: 220px;
        height: 50px;
    }
}
</style>

<style scoped lang="less">
section.wrapper .content{
    padding: 0;
    position: relative;
    &.hide{
        overflow: hidden;
    }
    .content_bg{
        padding: 76px 40px 40px;
        @media all and (max-width: 950px) {
            padding: 20px;
        }
        @media all and (max-width: 768px) {
            padding-top: 0;
        }
    }
}
.activities{
    margin-top: 20px;
}
.img{
    display: flex;
    height: 100%;
    align-items: center;
    img{
        max-width: 307px;
        width: 100%;
        height: auto;
    }
}
.article-empty{
    margin-top: 20px;
    font-weight: 600;
    font-size: 18px;
    color: #1a2744;
}
.activity{
    background: #fff;
    margin-bottom: 5px;
    border-radius: 10px;
    color: @blueColor;
    &.empty{
        header{
            cursor: default;
        }
    }
    header{
        border: 0;
        text-align: left;
        padding: 10px 0 10px 20px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        &>div:first-child{
            flex: 1 1 auto;
            overflow: hidden;
        }
        svg{
            fill: @blueColor;
            transition: fill .15s linear;
        }
        &::selection{
            background-color: transparent;
        }
        .activity-name{
            font: 500 18px/1.33 @secondFontFamily;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
        }
        .activity-date{
            font-size: 14px;
            font-weight: 500;
            line-height: 1.71;
            color: #9da3ae;
        }
        .activity-control{
            display: flex;
            button{
                width: 50px;
                height: 48px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                &:hover{
                    svg{
                        fill: @yellowColor;
                    }
                }
            }
            .collapse-idint{
                height: 48px;
                width: 60px;
                display: inline-flex;
                border-left: 1px solid #f5f5f5;
                align-items: center;
                justify-content: center;
                svg{
                    transform: rotate(180deg);
                    transition: transform .15s linear;
                }
                @media all and (max-width: 768px) {
                    display: none;
                }
                g{
                    transition: fill .15s linear;
                }
                &.opened{
                    svg{
                        transform: rotate(360deg);
                    }
                    g{
                        fill: @yellowColor;
                    }
                }
            }
        }
    }
    .slide-enter, .slide-leave-to{
        max-height: 0;
    }
    .slide-enter-to{
        max-height: 500px;
    }
    .activity-description{
        overflow: hidden;
        transition: max-height .4s;
        &>div{
            padding: 0 60px 10px 20px;
            text-align: left;
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: -0.11px;
            @media all and(max-width: 950px) {
                padding: 0 20px 10px;
            }
        }
    }
}

header{
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0,0,0,.03);
    ul{
        width: 100%;
        display: flex;
        list-style: none;
        background-color: #fff;
        border-radius: 10px;
        @media all and (max-width: 768px) {
            margin-top: 20px;
        }
        @media all and(max-width: 1000px) {
            display: block;
            li{
                width: 100% !important;
            }
        }
        li{
            width: 33.3333%;
            button{
                width: 100%;
            }
        }
    }
}
</style>
