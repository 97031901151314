<template>
    <div class="confirm" @click="$emit('confirm', false);">
        <div class="confirm-window" @click.stop="">
            <header>{{title}}</header>
            <footer>
                <CustomButton label="Скасувати" theme="dark" @click="$emit('confirm', false);"/>
                <CustomButton label="Підтвердити" @click="$emit('confirm', true);"/>
            </footer>
        </div>
    </div>
</template>

<script>
import CustomButton from "./CustomButton";

export default {
    name: "ConfirmComponent",
    components: {
        CustomButton
    },
    props: {
        title: {
            type: String,
            required: true
        }
    },
    created() {
        window.addEventListener("keyup", this.keyboardListener, false);
    },
    beforeDestroy() {
        window.removeEventListener("keyup", this.keyboardListener, false);
    },
    methods: {
        keyboardListener(e) {
            if (e.keyCode !== 27) {
                return;
            }

            this.$emit('confirm', false);
        }
    }

}
</script>

<style scoped lang="less">
    .confirm{
        position: sticky;
        top: 0;
        min-width: 320px;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: fade(@blueColor, 60%);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 99;
        @media all and (max-width: 950px){
            position: fixed;
        }

        .confirm-window{
            max-width: 610px;
            width: calc(100% - 40px);
            padding: 40px 80px;
            box-sizing: border-box;
            box-shadow: 0 24px 24px -14px rgba(157, 163, 174, 0.1);
            border: solid 1px #cdd4e0;
            background-color: #f5f5f5;
            border-radius: 10px;
            @media all and(max-width: 550px) {
                padding: 40px 15px;
            }
        }
        header{
            font-size: 16px;
            font-weight: 500;
            line-height: 1.5;
            letter-spacing: -0.11px;
            color: @blueColor;
            padding-bottom: 20px;
            position: relative;
            @media all and(max-width: 550px) {
                padding-left: 30px;
            }
            &:before{
                content: '';
                display: inline-block;
                width: 20px;
                height: 20px;
                margin-left: -40px;
                position: absolute;
                background: transparent url("./../assets/images/info-button.svg") center center no-repeat;
                @media all and(max-width: 550px) {
                    margin-left: -30px;
                }
            }
        }
        footer{
            border-top: 1px solid rgba(0,0,0,.03);
            padding-top: 20px;
            display: flex;
            justify-content: space-between;
            button{
                width: calc(50% - 4.5px);
            }
            @media all and(max-width: 500px) {

            }
        }
    }
</style>
